import * as React from 'react';
import {IHostProps, ISantaProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {IPropsInjectedByViewerScript} from '../types/app-types';
import {TPAComponentsProvider} from 'wix-ui-tpa';
import {TpaSettingsProviderForWidget, useStyles} from '@wix/tpa-settings/react';
import stylesParams from '@wix/wixstores-client-gallery/dist/src/components/GridGallery/stylesParams';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

export interface IProvidedGlobalProps {
  globals?: IPropsInjectedByViewerScript;
}

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & ISantaProps & {style: IHostProps['style']}) => {
    const {isRTL, isMobile} = props;
    const globals: IPropsInjectedByViewerScript = {...props};
    globals.styles = useStyles();
    globals.stylesParams = stylesParams;

    return (
      <PropsContext.Provider value={{globals}}>
        <TPAComponentsProvider value={{mobile: isMobile, rtl: isRTL}}>
          <Component {...props} />
        </TPAComponentsProvider>
      </PropsContext.Provider>
    );
  };
}

export function withTpaSettingsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & ISantaProps & {style: IHostProps['style']}) => {
    return (
      <TpaSettingsProviderForWidget hostStyle={props.host.style} publicData={props.publicData}>
        <Component {...props} />
      </TpaSettingsProviderForWidget>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return (props) => {
    return <PropsContext.Consumer>{(globalProps) => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}
